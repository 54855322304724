import React from "react"
import { Link } from "gatsby"

export default function Quote(props) {
  return (
    <section id="quote" className="u__vspacing s__green">
      <article className="u__container">
        <figure className="u__spacing quote">
          <blockquote className="h2 quote__content">
            {props.quote}
          </blockquote>
          <figcaption className="quote__attribution">
            <cite>
              <Link to="/about/team/david-yokum">{props.quotee}</Link>, {props.quoteePosition}
            </cite>
          </figcaption>
        </figure>
      </article>
    </section>
  )
}
