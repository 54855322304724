import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

export default function Projects(props) {
  // used to .filter the current page out of the query
  // since variables cannot be passed to a static query
  const slug = props.slug

  // limit is set to 1 more than will display, in case 1 is filtered out by slug
  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulProject(
            limit: 7
            sort: {order: [DESC, DESC], fields: [featured, publishDate]}
          ) {
            nodes {
              contentful_id
              methods {
                title
                contentful_id
              }
              slug
              title
            }
          }
        }
      `
      }
      render={data => (
        <section id="projects" className="u__vspacing s__primary project__recirc">
          <div className="u__container u__spacing">
            <div className="row justify-content-center">
              <div className="col-12 text-center">
                <h2 className="h1 mb-2">{props.header}</h2>
                <span className="lead">Learn more about our work across state, local, and national partners</span>
              </div>
              {data.allContentfulProject.nodes
                .filter(node => slug !== node.slug)
                .map((node, index) => {
                  // only output 6
                  if (index < 6) {
                    return (
                      <div className="col-sm-4 project__recirc__post d-flex flex-column text-center" key={node.contentful_id}>
                        <span className="project__recirc__post__meta">
                          {node.methods.map(method => method.title).join(", ")}
                        </span>
                        <h3 className="project__recirc__post__title h4">
                          <Link to={`/projects/${node.slug}`}>{node.title}</Link>
                        </h3>
                      </div>
                    )
                  } else {
                    return(null)
                  }
              })}
              <div className="col-12 pt-5 text-center">
                <Link to="/projects" className="project__recirc__landing-link h4">All Projects</Link>
              </div>
            </div>
          </div>
        </section>
      )}
    />
  )
}
