import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

export default function Events() {
  const handleClick = (event) => {
    if (event.target.nextSibling.classList.contains("show")) {
      event.target.nextSibling.classList.remove("show")
    } else {
      event.target.nextSibling.classList.add("show")
    }
    event.preventDefault()
  }
  
  return (
    <StaticQuery
      query={graphql`
        {
          allContentfulEvent(sort: {fields: startDate, order: ASC}) {
            nodes {
              body {
                childMarkdownRemark {
                  html
                }
              }
              contentful_id
              registration {
                displayText
                link
              }
              placeName
              address
              locationMapUrl
              speaker
              unformattedDate: startDate
              formattedDate: startDate(formatString: "dddd, MMMM DD, YYYY")
              difference: startDate(difference: "days")
              title
              time
            }
          }
        }
      `}
      render={data => {
        const upcomingEvents = data.allContentfulEvent.nodes.filter(node => parseInt(node.difference) <= 0).slice(0, 3)
        return (
          <>
            <div className="events__description">
              <p className="lead">We host a variety of events including speaker series and technical workshops to advance the mission of the Lab and share its work with a diverse set of stakeholders. Learn about our <Link to="/events">upcoming and previous events.</Link></p>
            </div>
            {(upcomingEvents.length === 0) ? (
              <div className="events__description">
                <h3 className="events__title">There are no upcoming events scheduled at this time.</h3>
              </div>
            ) : (
              upcomingEvents.map(node => {
                return (
                  <div className="events__description" key={node.contentful_id}>
                    <h3 className="events__title">
                      {node.registration.link &&
                        <a href={node.registration.link} className="events__link" target="_blank" rel="nofollow noreferrer">
                          {node.title}
                        </a>
                      }
                    </h3>
                    {node.speaker &&
                      <p className="h5 alt-bold events__speaker">{node.speaker}</p>
                    }
                    <p className="h5 events__date">
                      <time dateTime={node.unformattedDate}>{node.formattedDate}, {node.time}</time>
                    </p>
                      <div className="h5 events__location">
                        <p>
                        {node.placeName &&
                          <>
                            <span>{node.placeName}</span>
                            <br />
                          </>
                        }
                        {node.address &&
                          <>
                            <span>{node.address}</span>
                          </>
                        }
                        </p>
                      </div>
                    {node.locationMapUrl &&
                      <p className="h5 alt-bold events__map-link">
                        <a href={node.locationMapUrl} target="_blank" rel="nofollow noreferrer">
                          <span className="events__map__text">Map &rarr;</span>
                        </a>
                      </p>
                    }
                    {node.registration.link &&
                      <p className="h5 alt-bold events__register-link">
                        <a href={node.registration.link} target="_blank" className="events__register__link" rel="nofollow noreferrer">
                          {node.registration.displayText} &rarr;
                        </a>
                      </p>
                    }
                    {node.body.childMarkdownRemark.html &&
                      <>
                        <button className="events__body-button" aria-hidden="true" onClick={handleClick}>[&#43;] Show/Hide Event details</button>
                        <div className="events__body" dangerouslySetInnerHTML={{__html: node.body.childMarkdownRemark.html}}></div>
                      </>
                    }
                  </div>
                )
              })
            )}
          </>
        )
      }}
    />
  )
}
