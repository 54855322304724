import React from "react"
import { Link } from "gatsby"

export default function About(props) {
  return (
    <section id={props.id} className={`u__vspacing ${props.class}`}>
      <article className="u__container">
        <div className="u__spacing">
          <div className="row">
            <div className="col-md-3 col-lg-4">
              <h2 className="h1">{props.title}</h2>
            </div>
            <div className="col-md-9 col-lg-8">
              <p className="lead">{props.body}</p>
              {props.ctaLink &&
                <Link to={props.ctaLink} className="lead">{props.ctaLinkText}</Link>
              }
            </div>
          </div>
        </div>
      </article>
    </section>
  )
}
